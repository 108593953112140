import {PNOA_HISTORICO_ID} from './components/pnoa/config';
import {MAXAR_ID} from './components/ortos/config';

export const APP_NAME = 'donax';
export const DRAWER_WIDTH = 450;
export const SM_BREAKPOINT = 600;

export const INITIAL_VIEWPORT = {
  latitude: 43.65,
  longitude: -8.07,
  zoom: 14,
  bearing: 0,
  pitch: 0
};

export const MAPSTYLES = [
  {
    id: '/mapstyles/pnoa.json',
    label: 'PNOA',
    thumbnail: '/mapstyles/pnoa.png'
  },
  {
    id: PNOA_HISTORICO_ID,
    label: 'PNOA Histórico',
    thumbnail: '/mapstyles/pnoa.png'
  },
  {
    id: '/mapstyles/igo.json',
    label: 'IGN',
    thumbnail: '/mapstyles/igo.png'
  },
  {
    id: MAXAR_ID,
    label: 'MAXAR',
    thumbnail: '/mapstyles/satellite.png',
  },
];

export const INITIAL_MAPSTYLE = MAPSTYLES[0];


export const MAP_DETAILS = {
  REDNATURA: 'redNatura',
  DEM: 'dem',
  COLORIZED: 'colorized',
  SST: 'sst',
  FLOW: 'flow',
  MEAN: 'mean',
  SELECTION: 'selection',
  STATIONS: 'stations',
};

type MAP_DETAIL_STYLES_COLORS_TYPE = {
  id: number | string,
  name: string,
  type: string,
  paint: {
    color: string,
    opacity: number | undefined
  }
}

type MAP_DETAIL_STYLES_TYPE = {
  id: string,
  label: string,
  thumbnail: string,
  colors: Array<MAP_DETAIL_STYLES_COLORS_TYPE>
}


export const MAP_DETAIL_STYLES: Array<MAP_DETAIL_STYLES_TYPE>= [
  {
    id: MAP_DETAILS.REDNATURA,
    label: 'Red Natura',
    thumbnail: '/mapstyles/forestProtection.svg',
    colors: [
      {
        id: 1,
        name: 'Forestal MVMC',
        type: 'fill',
        paint: {
          color: '#5cb816',
          opacity: 1
        }
      }
    ]
  }
];
