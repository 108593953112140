import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey';


const slider = {
  color: 'primary.main',
  '& .MuiSlider-rail': {
    height: 6,
    opacity: 1,
    backgroundColor: 'primary.main',
  },
  '& .MuiSlider-thumb': {
    backgroundColor: 'secondary.main',
    width: 14,
    height: 14
  },
  '& .MuiSlider-markLabel': {
    color: grey[900],
    fontSize: '10px',
  },
};

export type configStepSliderProps = {
  dates: Array<number>,
}

export type StepSliderProps= {
  selectedDate: number,
  onDateChange: (selectedYear: number) => void,
}

interface StepSliderPropsConfig extends StepSliderProps {
  configStepSlider: configStepSliderProps
}

const StepSlider: FC<StepSliderPropsConfig> = ({configStepSlider, selectedDate, onDateChange}) => {
  const handleChange = (event: Event, value: number | number[]): void => {
    onDateChange(value as number);
  };

  return <>
    <Box sx={{mt: 0, mb: 1, mx: 1}}>
      <Typography component='h6' variant='body1' noWrap>Elige una fecha</Typography>
    </Box>
    <Box sx={{my: 0, mx: 1}}>
      <Slider
        step={null}
        min={configStepSlider.dates[0]}
        max={configStepSlider.dates[configStepSlider.dates.length - 1]}
        defaultValue={configStepSlider.dates[configStepSlider.dates.length - 1]}
        onChange={handleChange}
        color='secondary'
        marks={configStepSlider.dates.map((date) => ({value: date, label: date}))}
        value={selectedDate}
        sx={slider}
      />
    </Box>
  </>;
};

export default StepSlider;