import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

//UTILS
import {useTranslation} from 'react-i18next';
import Campaign from '../domain/entities/Campaign';
import {ButtonGroup} from '@mui/material';
import SampleButton from './SampleButton';

const typographySx = {
  fontStyle: 'italic',
  fontSize: 15
};

export const createCampaignTitles = (campaigns: Campaign[], onCampaignChange: (campaign: Campaign, measure: string) => void) => {
  const years = Array.from(new Set(campaigns.map(campaign => campaign.year)));
  return campaigns.length > 0 && years.sort((a, b) => b - a).map(year =>
    <div key={year}>
      <CampaignTitle titleKey={year.toString()}/>
      <ButtonGroup key={'bg' + year.toString()} sx={{mr: 1, mt: 1}} orientation="vertical" size="large" fullWidth>
        {
          campaigns.map(campaign => campaign.year === year &&
            <SampleButton key={'bt' + campaign.month + campaign.year} campaign={campaign} sampleMonth={campaign.month} onClickSample={onCampaignChange}/>)
        }
      </ButtonGroup>
    </div>
  );
};

export type CampaignTitleProps = {
  titleKey: string
};

const CampaignTitle: FC<CampaignTitleProps> = ({titleKey}) => {
  const {t} = useTranslation();
  return (
    <Box mt={2} mb={1} >
      <Typography
        variant='subtitle1'
        sx={typographySx}
      >
        {t(titleKey)}
      </Typography>
      <Divider/>
    </Box>
  );
};


export type CampaignMonthProps = {
  month: string
};


export const CampaignMonth: FC<CampaignMonthProps> = ({month}) => {
  const {t} = useTranslation();
  return (
    <Box mt={2} mb={1} >
      <Typography
        variant='subtitle2'
        sx={typographySx}
      >
        {t(month)}
      </Typography>
    </Box>
  );
};

export default CampaignTitle;
