import {MAP_DETAILS} from '../../config';

export const SST_ID = 'sst';
export const SST_DEFAULT_DATE = 2016;

const YEARS = [2016, 2017, 2018, 2019, 2020];
const MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

const TYPE = 'raster';
export const SST_DATES = YEARS.map(year => MONTHS.map(month => `${year}-${month}`)).flat();

export const SST_SOURCES = SST_DATES.reduce((acc, date) => {
  acc[`sst_${date}`] = {
    url: `${process.env.TILESERVER_URL}/data/SST-${date}.json`,
    type: TYPE
  };
  return acc;
}, {});
