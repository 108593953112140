import React, {FC, useState} from 'react';

//MUI
import Box from '@mui/material/Box';
import ResponsiveHeader from '@geomatico/geocomponents/Layout/ResponsiveHeader';
import SidePanel from '@geomatico/geocomponents/Layout/SidePanel';

//GEPROCAR
import DonaxTrunculusBlanco from './logos/DonaxTrunculusBlanco';

//UTILS
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import {DRAWER_WIDTH,  SM_BREAKPOINT} from '../config';
import ChartProfile from './ChartProfile';
import {Profile} from '../domain/entities/Measure';
import ChartFlow from './ChartFlow';
import ChartWaves from './ChartWaves';


export type MainProps = {
  widescreen: boolean,
  isleftdraweropen: boolean
}

const Main = styled(Box)<MainProps>(({isleftdraweropen}) => ({
  flexGrow: 1,
  padding: 0,
  position: 'absolute',
  top: 56,
  '@media (min-width: 0px) and (orientation: landscape)': {
    top: 48
  },
  ['@media (min-width: '+ SM_BREAKPOINT +'px)']: {
    top: 64
  },
  bottom: 0,
  right: 0,
  left: isleftdraweropen ? DRAWER_WIDTH : 0
})) as React.ElementType;

const responsiveHeaderSx = {
  '&.MuiAppBar-root': {
    zIndex: 1500
  },
  '& .ResponsiveHeader-title': {
    fontSize: '32px',
    fontWeight: 'bolder'
  }
};

export type LayoutProps = {
  mainContent: React.ReactElement,
  sidePanelContent: React.ReactElement,
  miniSidePanelSelectedActionId?: string,
  profile: Profile | null
  flow: [],
  waves: [],
  openWavesDialog: boolean,
  handleCloseProfile: () => void
  handleCloseFlow: () => void,
  handleCloseWaves: () => void,
  handleChangeDate: (startDate: Date, finalDate: Date, variable: string) => void
};

const Layout:  FC<LayoutProps> = ({
  mainContent,
  sidePanelContent,
  profile,
  flow,
  waves,
  openWavesDialog,
  handleCloseProfile,
  handleCloseFlow,
  handleCloseWaves,
  handleChangeDate
}) => {
  const widescreen = useMediaQuery(`@media (min-width:${SM_BREAKPOINT}px)`, {noSsr: true});
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);

  const handleClose = () => setIsSidePanelOpen(!isSidePanelOpen);

  const openProfileDialog = profile !== null;
  const openFlowDialog = flow.length > 0;

  return <>
    <ResponsiveHeader
      title='El GIS como herramienta de gestión: caso aplicado al arenal de Vilarrube'
      logo={ <DonaxTrunculusBlanco style={{margin: '0 20px'}}/> }
      onStartIconClick={widescreen ? undefined : handleClose}
      isStartIconCloseable={isSidePanelOpen}
      sx={responsiveHeaderSx}
    >
    </ResponsiveHeader>
    {sidePanelContent && isSidePanelOpen &&
      <SidePanel
        drawerWidth={DRAWER_WIDTH + 'px'}
        anchor="left"
        isOpen={isSidePanelOpen}
        onClose={handleClose}
        widescreen={widescreen}
      >
        {sidePanelContent}
      </SidePanel>
    }
    <Main widescreen={widescreen} isleftdraweropen={sidePanelContent && isSidePanelOpen}>
      {mainContent}
      <ChartProfile profile={profile} open={openProfileDialog} handleButtonClose={handleCloseProfile}/>
      <ChartFlow flow={flow} open={openFlowDialog} handleButtonClose={handleCloseFlow}/>
      <ChartWaves waves={waves} open={openWavesDialog} handleButtonClose={handleCloseWaves} handleChangeDate={handleChangeDate}/>
    </Main>
  </>;
};

export default Layout;
