import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {useAsync} from 'react-use';
import activateUserUseCase from '../useCases/activateUserUseCase';
import Activate from '../components/Login/Activate';


const ActivateView: FC = () => {

  const {uid, token} = useParams();

  const state = useAsync(async () => {
    if (uid && token) {
      return activateUserUseCase(uid, token);
    } else {
      return Promise.reject();
    }
  }, []);

  return state.loading ?
    <div>Loading...</div> :
    state.value ? <Activate /> : <Activate invalid={true} />;


};

export default ActivateView;