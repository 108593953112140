import {Sources} from 'mapbox-gl';
import Campaign from '../../domain/entities/Campaign';
import {MAP_DETAILS} from '../../config';

const sourcesConstructor: (campaign: Campaign | null, measure: string, sstDate: number | null, flow: boolean) => Sources = (campaign: Campaign | null, measure: string, sstDate: number | null, flow: boolean) => {
  const sources: Sources =  {
    redNatura: {
      type: 'raster',
      tiles: [
        'https://wms.mapama.gob.es/sig/Biodiversidad/RedNatura/wms.aspx?bbox={bbox-epsg-3857}&styles=default&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=PS.ProtectedSite'
      ],
      bounds: [-7.1191,42.8477,-4.4934,43.75],
      minzoom: 7,
      maxzoom: 23
    }
  };
  if (campaign && measure == 'profile') {
    sources[MAP_DETAILS.DEM] = {
      type: 'raster-dem',
      url: `${process.env.TILESERVER_URL}/data/${transformMonth(campaign.month)}-${campaign.year}.json`
    };
    sources[MAP_DETAILS.COLORIZED] = {
      type: 'raster',
      url: `${process.env.TILESERVER_URL}/data/${transformMonth(campaign.month)}-${campaign.year}-color.json`
    };
  }

  if (campaign && measure == 'mean') {
    sources[MAP_DETAILS.MEAN] = {
      type: 'raster',
      url: `${process.env.TILESERVER_URL}/data/${transformMonth(campaign.month)}-${campaign.year}-medias.json`
    };
  }

  if (campaign && measure == 'selection') {
    sources[MAP_DETAILS.SELECTION] = {
      type: 'raster',
      url: `${process.env.TILESERVER_URL}/data/${transformMonth(campaign.month)}-${campaign.year}-seleccion.json`
    };
  }

  if (sstDate) {
    sources[MAP_DETAILS.SST] = {
      type: 'raster',
      url: `${process.env.TILESERVER_URL}/data/SST-${sstDate}.json`
    };
  }

  if (flow) {
    sources[MAP_DETAILS.FLOW] = {
      type: 'geojson',
      data: { 'type': 'Feature', 'properties':
          { 'name': 'Rego Das Mestas', 'num': 1445, 'altitude': 23 },
      'geometry': { 'type': 'Point', 'coordinates': [ -8.039913, 43.624768 ] } }
    };
  }

  sources[MAP_DETAILS.STATIONS] = {
    type: 'geojson',
    data: '/stations.geojson'
  };

  return sources;
};

const transformMonth = (month: number): string => {
  switch (month) {
  case 1:
    return 'ENER';
  case 2:
    return 'FEBR';
  case 3:
    return 'MARZ';
  case 6:
    return 'JUN';
  case 9:
    return 'SEPT';
  case 10:
    return 'OCT';
  case 12:
    return 'DIC';
  default:
    return '';
  }
};

export default sourcesConstructor;