//REACT
import React, {FC} from 'react';
import Draggable from 'react-draggable';

//MUI
import {LineChart, lineElementClasses, markElementClasses} from '@mui/x-charts/LineChart';
import Dialog from '@mui/material/Dialog';
import Paper, {PaperProps} from '@mui/material/Paper';
import {Button, DialogTitle, IconButton} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const variables = [
  'Hm0',
  'Tm02',
  'Tp',
  'DirM',
  'Hm0_V',
  'DirM_V',
  'Hm0_F1',
  'Tm02_F1',
  'DirM_F1',
  'Hm0_F2',
  'Tm02_F2',
  'DirM_F2',
  'VelV',
  'DirV',
  'Cg',
  'E',
  'p',
];

interface StringKeyObject {
  [key: string]: string;
}

const getVariableName = (variableName: string) => {
  return variablesNames[variableName];
};

const variablesNames: StringKeyObject = {
  Hm0: 'Altura significante espectral (m)',
  Tm02: 'Periodo medio del oleaje (s)',
  Tp: 'Periodo de pico del oleaje (s)',
  DirM: 'Dirección media 0º N y 90º E',
  Hm0_V: 'Altura significante mar de viento (m)',
  DirM_V: 'Dirección media mar de viento 0º N y 90º E',
  Hm0_F1: 'Altura significante media mar de fondo 1 (m)',
  Tm02_F1: 'Periodo medio mar de fondo 1 (s)',
  DirM_F1: 'Dirección media mar de fondo 1 0º N y 90º E',
  Hm0_F2: 'Altura significante media mar de fondo 2 (m)',
  Tm02_F2: 'Periodo medio mar de fondo 2 (s)',
  DirM_F2: 'Dirección media mar de fondo 2 0º N y 90º E',
  VelV: 'Velocidad del Viento (m/s)',
  DirV: 'Dirección del Viento 0º N y 90º E',
  Cg: 'Celeridad de grupo (m/s)',
  E: 'Energía (J)',
  p: 'Presión (N/m2)',
};

type Wave = {
  date_time: string;
  [key: string]: any;
}

export type ChartWavesProps = {
  waves: Wave[];
  open: boolean;
  handleButtonClose: () => void;
  handleChangeDate: (startDate: Date, finalDate: Date, variable: string) => void;
}

const PaperComponent = (props: PaperProps) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const prepareData = (waves: Wave[]) => {
  // get the keys of the waves array
  let variable: string;
  if (waves && waves.length > 0) {
    variable = Object.keys(waves[0])[0];
  }
  return waves.map((m) => {
    return {
      x: new Date(m['date_time']),
      y: m[variable],
    };
  });
};

const ChartWaves: FC<ChartWavesProps> = ({waves, open, handleButtonClose, handleChangeDate}) => {

  const theme = useTheme();
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [selectedVariable, setSelectedVariable] = React.useState('');

  const handleVariableChange = (event: any) => {
    const variable = event.target.value;
    setSelectedVariable(variable);
  };

  const handleQueryWaves = () => {
    if (startDate && endDate && selectedVariable) {
      handleChangeDate(startDate, endDate, selectedVariable);
    }
  };

  const onChangeInitialDate = (value: any) => {
    setStartDate(value.toDate());
  };

  const onChangeFinalDate = (value: any) => {
    setEndDate(value.toDate());
  };

  let data = [];
  let xAxisData: Date[] = [];
  let seriesData: number[] = [];

  if (waves) {
    data = prepareData(waves);
    xAxisData = data.map(d => d.x);
    seriesData = data.map(d => d.y);
  }

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={false}
      hideBackdrop
    >
      <DialogTitle id="draggable-dialog-title" style={{cursor: 'move', backgroundColor: theme.palette.primary.main, color: 'white'}}>
        Evolución temporal del Oleaje
        <IconButton style={{position: 'absolute', right: '8px', top: '8px'}} onClick={handleButtonClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div style={{backgroundColor: 'white'}}>
        <LineChart
          xAxis={[{
            data: xAxisData,
            scaleType: 'time',
            valueFormatter: (date) =>
              date.toLocaleDateString('es-ES', {
                // you can use undefined as first argument
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              })
          }]}
          series={[{ data: seriesData, id: 'seriesId', color: 'blue', label: getVariableName(selectedVariable)}]}
          width={1200}
          height={600}
          sx={{
            [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
              strokeWidth: 1,
            },
            '.MuiLineElement-series-seriesId': {
              strokeDasharray: '3 4 5 2',
            },
            [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]: {
              fill: '#fff',
            },
            [`& .${markElementClasses.highlighted}`]: {
              stroke: 'none',
            },
          }}
        />
      </div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker label="Fecha Inicial" sx={{ margin: 2 }} onChange={onChangeInitialDate}
            format="DD MMMM YYYY"/>
        </LocalizationProvider>
        <Select
          value={selectedVariable}
          onChange={handleVariableChange}
          sx={{ margin: 2 }}
        >
          {variables.map((variable) => (
            <MenuItem key={variable} value={variable}>
              {variable}
            </MenuItem>
          ))}
        </Select>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker label="Fecha Final" sx={{ margin: 2 }} onChange={onChangeFinalDate}
            format="DD MMMM YYYY"/>
        </LocalizationProvider>
        <Button variant="contained" color="primary" onClick={handleQueryWaves} sx={{ margin: 2 }}>Consultar</Button>
      </Box>
    </Dialog>
  );
};

export default ChartWaves;