class Measure {
  public profile: number;
  public mean: number;
  public selection: number;
  public date: Date;

  constructor(date: Date, profile: number, mean: number, selection: number) {
    this.profile = profile;
    this.mean = mean;
    this.selection = selection;
    this.date = date;
  }
}

class Profile {
  public measures: Measure[];

  constructor(measures: Measure[]) {
    this.measures = measures;
  }
}

export { Measure, Profile };