import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';



//UTILS
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

const containerSx: SxProps = {
  my: 8,
  mx: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};
const SignUpForm: FC = ({children}) => {

  return <Grid container component='main' sx={{height: '100vh'}}>
    <Grid item xs={false} sm={4} md={5} component={Paper} elevation={6} square
      sx={{bgcolor: 'secondary.main', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    </Grid>
    <Grid item xs={12} sm={8} md={7}>
      <Box sx={containerSx}>
        {children}
      </Box>
    </Grid>
  </Grid>;
};

export default SignUpForm;