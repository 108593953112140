import React, {FC, useMemo, useState} from 'react';

//MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

//MUI-ICONS
import LockIcon from '@mui/icons-material/Lock';

//UTILS
import {useTranslation} from 'react-i18next';
import {FormHelperText} from '@mui/material';
import LoginWrapper from './LoginWrapper';


const helperTextSx = {
  '& .MuiFormHelperText-root': {
    color: 'error.main'
  }
};

export type userDataProps = {
  first_name: string,
  last_name: string,
  nif: string,
  email: string,
  password: string,
  re_password: string
}

export type SignUpErrors = {
  [key: string]: Array<string>,
};

export type SignUpFormProps = {
  errors: SignUpErrors,
  onSubmit: (userData: userDataProps) => void,
}

const SignUpForm: FC<SignUpFormProps> = ({errors: formErrors, onSubmit}) => {

  const {t} = useTranslation();

  const initialUserData: userDataProps = {
    first_name: '',
    last_name: '',
    nif: '',
    email: '',
    password: '',
    re_password: ''
  };

  //VALIDATIONS
  const [userData, setUserData] = useState(initialUserData);

  const validPassword = useMemo<boolean>(() =>
    userData.password === userData.re_password, [userData]
  );

  const allFieldsWithData = useMemo<boolean>(() =>
    Object.values(userData).every((value) => value.length > 0), [userData]
  );

  const isSubmitable = useMemo<boolean>(() =>
    validPassword && allFieldsWithData,[validPassword, allFieldsWithData]
  );

  const handleSubmit = () => {
    onSubmit(userData);
  };

  const handleFieldChange = (e:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setUserData({...userData, [`${e.target.id}`]: e.target.value});

  const handleFieldError = (property: string) => {
    if (formErrors){
      if (property in formErrors) {
        return <div>
          {formErrors[property].map((error, index) => <FormHelperText key={index}>{error}</FormHelperText>)}
        </div>;
      } else {
        return undefined;
      }
    }
  };

  return <LoginWrapper>
    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
      <LockIcon/>
    </Avatar>
    <Typography component='h1' variant='h5'>{t('login.signUp')}</Typography>
    <Box sx={{mt: 1}}>
      <TextField margin='normal' required fullWidth id='first_name' label={t('login.firstName')} name='first_name' autoFocus
        onChange={handleFieldChange}
        helperText={handleFieldError('first_name')} sx={helperTextSx}
      />
      <TextField margin='normal' required fullWidth id='last_name' label={t('login.lastName')} name='last_name'
        onChange={handleFieldChange}
        helperText={handleFieldError('last_name')} sx={helperTextSx}
      />
      <TextField margin='normal' required fullWidth id='nif' label={t('login.nif')} name='nif'
        onChange={handleFieldChange}
        helperText={handleFieldError('nif')} sx={helperTextSx}
      />
      <TextField margin='normal' required fullWidth id='email' label={t('login.email')} name='email'
        autoComplete='email' onChange={handleFieldChange}
        helperText={handleFieldError('email')} sx={helperTextSx}
      />
      <TextField margin='normal' required fullWidth name='password' label={t('login.password')} type='password' id='password'
        onChange={handleFieldChange}
        helperText={handleFieldError('password')} sx={helperTextSx}
      />
      <TextField margin='normal' required fullWidth name='re_password' label={t('login.re_password')}
        type='password' id='re_password'
        onChange={handleFieldChange}
        helperText={!validPassword && t('login.passwordErrorText')} sx={helperTextSx}
      />
      <Button fullWidth variant='contained' sx={{mt: 3, mb: 2}} onClick={handleSubmit} disabled={!isSubmitable}>
        {t('login.signMeUp')}
      </Button>
    </Box>
  </LoginWrapper>;
};

export default SignUpForm;