import AuthImpl from '../serviceImpl/AuthImpl';
import LocalStorageImpl from '../serviceImpl/LocalStorageImpl';
import {LoginError} from '../domain/services/types';

const loginUseCase = async (user: string, password: string): Promise<void | LoginError> => {

  const login = await AuthImpl.login(user, password);

  if ( login ) {
    if ('access' in login) {
      await LocalStorageImpl.setCredentials(login.access, login.refresh);
      return Promise.resolve();
    }
    if ('detail' in login) {
      return Promise.resolve(login);
    }
  } else {
    return Promise.reject();
  }


};

export default loginUseCase;