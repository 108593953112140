import React, {FC} from 'react';

//MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import LockIcon from '@mui/icons-material/Lock';

//UTILS
import {useTranslation} from 'react-i18next';
import LoginWrapper from './LoginWrapper';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';

type ActivateProps = {
  invalid?: boolean
}

const Activate: FC<ActivateProps> = ({invalid = false}) => {

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate(`/${i18n.resolvedLanguage}/login`);
  };

  return <LoginWrapper>
    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
      <LockIcon/>
    </Avatar>
    <Typography component='h1' variant='h5'>{invalid ? t('login.activateInvalid') : t('login.activate')}</Typography>
    <Box sx={{mt: 1}}>
      <Typography component='body' variant='body1'>{invalid ? t('login.activateTextInvalid') : t('login.activateText')}</Typography>
    </Box>
    <Button fullWidth variant='contained' sx={{mt: 3, mb: 2}} onClick={handleGoToLogin}>
      {t('login.activateButton')}
    </Button>
  </LoginWrapper>;
};

export default Activate;