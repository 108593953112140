import React, {useEffect, useMemo, useState} from 'react';

//CLAS
import Layout from '../../components/Layout';
import SidePanelContent from './SidePanelContent';
import MainContent from './MainContent';

//UTILS
import {INITIAL_MAPSTYLE, MAP_DETAILS} from '../../config';
import {Style} from 'mapbox-gl';

// PNOA
import {PNOA_HISTORICO_BASEMAP, PNOA_DEFAULT_YEAR, PNOA_HISTORICO_ID} from '../../components/pnoa/config';

// ORTOS
import {MAXAR_HISTORICO_BASEMAP, MAXAR_DEFAULT_YEAR, MAXAR_ID} from '../../components/ortos/config';

// CAMPAIGNS
import Campaign from '../../domain/entities/Campaign';
import {
  getCampaignsUseCase,
  getFlowUseCase,
  getProfileUseCase,
  getWavesUseCase
} from '../../useCases/apiUseCase';
import {Measure, Profile} from '../../domain/entities/Measure';

const Index = () => {
  const [mapStyleId, setMapStyleId] = useState<string>(INITIAL_MAPSTYLE.id);
  const [PNOASelectedYear, setPNOASelectedYear] = useState<number>(PNOA_DEFAULT_YEAR);
  const [maxarSelectedDate, setMaxarSelectedDate] = useState<string>(MAXAR_DEFAULT_YEAR);
  const [mapDetail, setMapDetail] = useState<Array<string | never>>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [measure, setMeasure] = useState<string>('profile');
  const [profile, setProfile] = useState<Profile | null>(null);
  const [sstDate, setSSTDate] = useState<number | null>(null);
  const [sstDisabled, setSstDisabled] = useState<boolean>(true);
  const [flowDisabled, setFlowDisabled] = useState<boolean>(false);
  const [wavesDisabled, setWavesDisabled] = useState<boolean>(false);
  const [flow, setFlow] = useState<[]>([]);
  const [waves, setWaves] = useState<[]>([]);
  const [startWavesDate, setStartWavesDate] = React.useState<Date | null>();
  const [endWavesDate, setEndWavesDate] = React.useState<Date | null>();
  const [selectedVariable, setSelectedVariable] = useState<string | null>();

  useEffect(() => {
    const fetchCampaigns = async () => {
      const campaigns = await getCampaignsUseCase();
      setCampaigns(campaigns);
    };
    fetchCampaigns();
  }, []);
  const fetchFlow = async () => {
    const flow = await getFlowUseCase();
    setFlow(flow);
  };

  const fetchWaves = async (initialDate: Date, finalDate: Date, variable: string) => {
    const waves = await getWavesUseCase(initialDate, finalDate, variable);
    if (waves.length > 0) {
      setWaves(waves);
    }
  };

  const mapStyle = useMemo<Style | string>(() => {
    if (mapStyleId !== PNOA_HISTORICO_ID && mapStyleId !== MAXAR_ID) {
      return mapStyleId;
    }
    if (mapStyleId == PNOA_HISTORICO_ID) {
      return PNOA_HISTORICO_BASEMAP(PNOASelectedYear) as unknown as Style;
    }
    if (mapStyleId == MAXAR_ID) {
      return MAXAR_HISTORICO_BASEMAP(maxarSelectedDate) as unknown as Style;
    }

    return mapStyleId;
  }, [mapStyleId, PNOASelectedYear, maxarSelectedDate]);

  const handleMapStyleChange = (mapStyleId: string) => setMapStyleId(mapStyleId);

  const handleGetMapProfile = (lat: number, lon: number) => {
    const getProfile = async (lat: number, lon: number) => {
      const valuesFromApi = await getProfileUseCase(lat, lon);
      const measures = [];
      for (let i = 0; i < valuesFromApi.length; i++) {
        const measure = new Measure(
          valuesFromApi[i]['date'],
          valuesFromApi[i]['profile'],
          valuesFromApi[i]['mean'],
          valuesFromApi[i]['selection']
        );
        measures.push(measure);
      }
      const profile = new Profile(measures);
      setProfile(profile);
    };
    getProfile(lat, lon);
  };

  const handleCampaignChange = (campaign: Campaign, measure: string) => {
    if (selectedCampaign !== campaign) {
      setSelectedCampaign(campaign);
      setMeasure(measure);
    } else {
      setSelectedCampaign(null);
      setMeasure('');
    }
  };

  const handleMapDetailChange = (newMapDetail: Array<string | never>) => {
    return newMapDetail !== mapDetail && setMapDetail(newMapDetail);
  };

  const handleSSTDateChange = (value: number) => {
    setSSTDate(value);
  };

  const handleSSTVisibleChange = (visible: boolean) => {
    setSstDisabled(visible);
  };

  const handleFlow = (value: boolean) => {
    if (value) {
      fetchFlow();
    }
    setFlowDisabled(value);
  };

  const handleWaves = (value: boolean) => {
    setWavesDisabled(value);
  };

  const handleCloseFlow = () => {
    setFlowDisabled(false);
    setFlow([]);
  };

  const handleCloseWaves = () => {
    setWavesDisabled(false);
    setWaves([]);
  };

  const handleWavesDateChange = (startDate: Date, finalDate: Date, variable: string) => {
    setStartWavesDate(startDate);
    setEndWavesDate(finalDate);
    fetchWaves(startDate, finalDate, variable).then(
      () => console.log('Waves fetched')
    );
  };

  const sidePanelContent = <SidePanelContent
    mapStyleId={mapStyleId}
    mapDetail={mapDetail}
    campaigns={campaigns}
    PNOASelectedYear={PNOASelectedYear}
    ORTOSSelectedDate={maxarSelectedDate}
    sstDisabled={sstDisabled}
    flowDisabled={flowDisabled}
    wavesDisabled={wavesDisabled}
    onFlowChange={handleFlow}
    onWavesClick={handleWaves}
    onStyleChange={handleMapStyleChange}
    onMapDetailChange={handleMapDetailChange}
    onPNOAYearChange={setPNOASelectedYear}
    onMaxarDateChange={setMaxarSelectedDate}
    onCampaignChange={handleCampaignChange}
    onSSTDateChange={handleSSTDateChange}
    onSSTDisabledChange={handleSSTVisibleChange}
  />;

  const mainContent = <MainContent
    mapStyle={mapStyle}
    mapDetail={mapDetail}
    campaign={selectedCampaign}
    measure={measure}
    sstDate={sstDate}
    sstDisabled={sstDisabled}
    flowDisabled={flowDisabled}
    flow={flow}
    handleMapClick={(e) => handleGetMapProfile(e.lngLat.lat, e.lngLat.lng)}
  />;

  return <Layout
    sidePanelContent={sidePanelContent}
    mainContent={mainContent}
    profile={profile}
    flow={flow}
    waves={waves}
    openWavesDialog={wavesDisabled}
    handleCloseProfile={() => setProfile(null)}
    handleCloseFlow={() => handleCloseFlow()}
    handleCloseWaves={() => handleCloseWaves()}
    handleChangeDate={(startDate: Date, finalDate: Date, variable: string) => handleWavesDateChange(startDate, finalDate, variable)}
  />;
};

export default Index;
