import React, {FC} from 'react';

import {Navigate, Outlet} from 'react-router-dom';
import i18n from 'i18next';
import {useAsync} from 'react-use';
import isLoggedUseCase from '../useCases/isLoggedUseCase';

const PrivateRoute: FC = () => {
  const state = useAsync(isLoggedUseCase, []);


  return state.loading ?
    <div>Loading...</div> :
    state.value ? <Outlet /> : <Navigate to={`/${i18n.resolvedLanguage}/login`} />;
};

export default PrivateRoute;