import ApiImpl from '../serviceImpl/ApiImpl';
import Campaign from '../domain/entities/Campaign';

const getCampaignsUseCase = async ( ): Promise<Campaign[]> => {
  return ApiImpl.getCampaigns();
};

const getProfileUseCase = async (lat: number, lon: number): Promise<[]> => {
  return ApiImpl.getProfile(lat, lon);
};

const getFlowUseCase = async (): Promise<[]> => {
  return ApiImpl.getFlow();
};

const getWavesUseCase = async (initialDate: Date, finalDate: Date, variable: string): Promise<[]> => {
  return ApiImpl.getWaves(initialDate, finalDate, variable);
};

export {
  getCampaignsUseCase,
  getProfileUseCase,
  getFlowUseCase,
  getWavesUseCase
};