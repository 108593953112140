import React, {FC} from 'react';
import {BrowserRouter, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom';
import i18n from 'i18next';
import MapView from '../views/Map';
import LoginView from '../views/Login';
import ActivateView from '../views/Activate';
import PrivateRoute from './PrivateRoute';
import SignUp from '../views/SignUp';

const LangSetter: FC = () => {
  const {lang} = useParams();
  if (i18n.resolvedLanguage !== lang) {
    i18n.changeLanguage(lang);
  }
  return <Outlet/>;
};

const AppRoutes: FC = () =>
  <BrowserRouter >
    <Routes>
      <Route path=":lang" element={<LangSetter/>} >
        <Route path="" element={<Navigate to="map" />} />
        <Route path="map" element={<PrivateRoute/>} >
          <Route path="" element={<MapView/>} />
        </Route>
        <Route path="login" element={<LoginView/>} />
        <Route path="signup" element={<SignUp/>} />
        <Route path="activate/:uid/:token" element={<ActivateView />} />
        <Route path="*" element={<>404</>}/>
      </Route>
      <Route path="*" element={<Navigate to={i18n.resolvedLanguage}/>}/>
    </Routes>
  </BrowserRouter>;

export default AppRoutes;
