import {AnyLayer} from 'mapbox-gl';
import {MAP_DETAILS} from '../../config';

const layerConstructorRedNatura = (): Array<AnyLayer> => ([
  {
    'id': MAP_DETAILS.REDNATURA,
    'type': 'raster',
    'source': MAP_DETAILS.REDNATURA,
    'paint': {
      'raster-opacity': 0.5
    }
  }
]);

const demConstructor = (): Array<AnyLayer> => ([
  {
    'id': MAP_DETAILS.DEM,
    'type': 'hillshade',
    'source': MAP_DETAILS.DEM,
    'paint': {
      'hillshade-exaggeration': 1
    }
  }
]);

const colorizedConstructor = (): Array<AnyLayer> => ([
  {
    'id': MAP_DETAILS.COLORIZED,
    'type': 'raster',
    'source': MAP_DETAILS.COLORIZED
  }
]);

const sstConstructor = (): Array<AnyLayer> => ([
  {
    'id': MAP_DETAILS.SST,
    'type': 'raster',
    'source': MAP_DETAILS.SST,
    'paint': {
      'raster-opacity': 0.5
    }
  }
]);

const flowConstructor = (): Array<AnyLayer> => ([
  {
    'id': MAP_DETAILS.FLOW,
    'type': 'circle',
    'source': MAP_DETAILS.FLOW,
    'paint': {
      'circle-radius': 10,
      'circle-color': '#ff0000'
    }
  }
]);

const meanConstructor = (): Array<AnyLayer> => ([
  {
    'id': MAP_DETAILS.MEAN,
    'type': 'raster',
    'source': MAP_DETAILS.MEAN
  }
]);

const selectionConstructor = (): Array<AnyLayer> => ([
  {
    'id': MAP_DETAILS.SELECTION,
    'type': 'raster',
    'source': MAP_DETAILS.SELECTION
  }
]);

const stationsConstructor = (): Array<AnyLayer> => ([
  {
    'id': MAP_DETAILS.STATIONS,
    'type': 'circle',
    'source': MAP_DETAILS.STATIONS,
    'paint': {
      'circle-color': 'rgb(250,240,191)',
      'circle-radius': 5,
      'circle-opacity': 0.5,
      'circle-stroke-color': 'rgb(0,0,0)',
      'circle-stroke-width': 1,
      'circle-stroke-opacity': 0.8
    }
  },
  {
    'id': MAP_DETAILS.STATIONS + '-text',
    'type': 'symbol',
    'source': MAP_DETAILS.STATIONS,
    'layout': {
      'text-field': ['get', 'Muestra'],
      'text-size': 12,
      'text-offset': [0, 1],
      'text-anchor': 'top'
    },
    'paint': {
      'text-color': 'rgb(0,0,0)',
      'text-halo-color': 'rgb(255,255,255)',
      'text-halo-width': 1
    }
  }
]);

const layerConstructor = {
  redNatura: layerConstructorRedNatura,
  dem: demConstructor,
  colorized: colorizedConstructor,
  sst: sstConstructor,
  flow: flowConstructor,
  mean: meanConstructor,
  selection: selectionConstructor,
  stations: stationsConstructor
};

export default layerConstructor;