
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '@mui/material';
import Campaign from '../domain/entities/Campaign';
import {CampaignMonth} from './CampaignTitle';


export type SampleButtonProps = {
  campaign: Campaign,
  sampleMonth: number,
  onClickSample: (campaign: Campaign, measure: string) => void
};

const getMonthLabel = (month: string) => {
  switch (month) {
  case '1':
    return 'january';
  case '2':
    return 'february';
  case '3':
    return 'march';
  case '4':
    return 'april';
  case '5':
    return 'mai';
  case '6':
    return 'june';
  case '7':
    return 'july';
  case '8':
    return 'august';
  case '9':
    return 'september';
  case '10':
    return 'october';
  case '11':
    return 'november';
  case '12':
    return 'december';
  default:
    return '';
  }
};

const SampleButton: FC<SampleButtonProps> = ({
  campaign,
  sampleMonth,
  onClickSample
}) => {
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [isMeanClicked, setIsMeanClicked] = useState(false);
  const [isSelectionClicked, setIsSelectionClicked] = useState(false);

  const {t} = useTranslation();
  const handleClickProfile = (campaign: Campaign) => {
    setIsProfileClicked(!isProfileClicked);
    onClickSample(campaign, 'profile');
  };

  const handleClickMean = (campaign: Campaign) => {
    setIsMeanClicked(!isMeanClicked);
    onClickSample(campaign, 'mean');
  };

  const handleClickSelection = (campaign: Campaign) => {
    setIsSelectionClicked(!isSelectionClicked);
    onClickSample(campaign, 'selection');
  };

  return (
    <div>
      <CampaignMonth month={t(getMonthLabel(sampleMonth.toString()))}/>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Button variant='contained' color={isProfileClicked ? 'secondary' : 'primary'} onClick={() => {
          handleClickProfile(campaign);
        }}>
          {t('profile')}
        </Button>
        <Button variant='contained' color='inherit' onClick={() => {
          handleClickMean(campaign);
        }}>
          {t('mean')}
        </Button>
        <Button variant='contained' color='success' onClick={() => {
          handleClickSelection(campaign);
        }}>
          {t('selection')}
        </Button>
      </div>
    </div>
  );
};

export default SampleButton;