export const MAXAR_ID = 'maxar';
export const MAXAR_DEFAULT_YEAR = '20200910';

export const MAXAR_DAYS = [
  '20190213', '20190411', '20190421', '20190712', '20190721', '20191227', '20200324', '20200910'
];

export const params = {
  service: 'WMTS',
  request: 'GetTile',
  version: '1.0.0',
  height: 256,
  width: 256,
  format: 'image/png',
  style: '',
  tileMatrixSet: 'EPSG:900913',
  tileMatrix: 'EPSG:900913:{z}',
  tileRow: '{y}',
  tileCol: '{x}'
};

const queryString = Object.keys(params).map(function(key) {
  return key + '=' + params[key];
}).join('&');

const MAXAR_HISTORICO_SOURCES = MAXAR_DAYS.reduce((acc, day) => ({
  ...acc,
  [day]: {
    'type': 'raster',
    'tiles': [
      `${process.env.GEOSERVER_URL}/gwc/service/wmts?layer=trunculus:${day}&` + queryString
    ],
    'tileSize': 256
  }
}), {});

export const MAXAR_HISTORICO_BASEMAP = (day) => ({
  'version': 8,
  'name': 'MAXAR',
  'glyphs': 'https://tileserver.donaxtrunculus.info/fonts/{fontstack}/{range}.pbf',
  'sources': {
    ...MAXAR_HISTORICO_SOURCES,
  },
  'layers': [
    {
      'id': 'background',
      'type': 'background',
      'paint': {
        'background-color': '#F4F9F4'
      }
    },
    {
      'id': 'maxar',
      'type': 'raster',
      'source': `${day}`,
      'maxzoom': 22
    }
  ]
});