import React, {FC, useState,} from 'react';
import SignUpForm, {userDataProps} from '../components/Login/SignUpForm';
import signUpUseCase from '../useCases/signUpUseCase';
import {UserCreated, UserCreateParamsErrors} from '../domain/services/types';
import SignUpConfirm from '../components/Login/SignUpConfirm';

const SignUpView: FC = () => {
  const [errors, setErrors] = useState<UserCreateParamsErrors>({});
  const [alreadySubmited, setAlreadySubmited] = useState(false);

  const handleSubmit = (userData: userDataProps) => {
    signUpUseCase(userData)
      .then((result) => {
        if((result as UserCreated).uuid){
          setAlreadySubmited(true);
        } else {
          setErrors(result as UserCreateParamsErrors);
        }
      });
  };

  return alreadySubmited ? <SignUpConfirm /> : <SignUpForm errors={errors} onSubmit={handleSubmit}/>;
};

export default SignUpView;