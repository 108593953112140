import React, {FC} from 'react';

//MUI

import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {MAXAR_DAYS} from './ortos/config';


export type SelectMaxarProps= {
  selectedDate: string,
  onDateChange: (selectedYear: string) => void,
}

const SelectMaxar: FC<SelectMaxarProps> = ({selectedDate, onDateChange}) => {

  const handleChange = (event: SelectChangeEvent): void => {
    onDateChange(event.target.value);
  };

  return <FormControl sx={{ m: 1, width: 200 }}>
    <InputLabel id="select-maxar-date-label">Imagen</InputLabel>
    <Select
      labelId="select-maxar-date-label"
      id="select-maxar-date"
      value={selectedDate}
      label="Age"
      onChange={handleChange}
    >
      {MAXAR_DAYS.map((day) => (
        <MenuItem
          key={day}
          value={day}
        >
          {`${day.slice(6, 8)}-${day.slice(4, 6)}-${day.slice(0, 4)}`}
        </MenuItem>
      ))}
    </Select>
  </FormControl>;
};

export default SelectMaxar;
