import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import grey from '@mui/material/colors/grey';
import {SST_DATES} from './config';


const marks = SST_DATES.map((date, index) =>
  ({value: index}));

const slider = {
  color: 'primary.main',
  '& .MuiSlider-rail': {
    height: 6,
    opacity: 1,
    backgroundColor: 'primary.main',
  },
  '& .MuiSlider-thumb': {
    backgroundColor: 'secondary.main',
    width: 14,
    height: 14
  },
  '& .MuiSlider-markLabel': {
    color: grey[900],
    fontSize: '10px',
  },
};

const valueLabelFormat = (value: number) => {
  return SST_DATES[value];
};

export type SSTStepSliderProps= {
  onDateChange: (selectedYear: number) => void,
  disabled: boolean
}

const SSTStepSlider: FC<SSTStepSliderProps> = ({onDateChange, disabled}) => {
  const handleChange = (event: Event, value: number | number[]): void => {
    const index = value as number;
    const date = parseInt(SST_DATES[index].replace('-', ''));
    onDateChange(date);
  };

  return <>
    <Box sx={{my: 0, mx: 4}}>
      <Slider
        disabled={disabled}
        step={null}
        max={marks[marks.length - 1].value}
        min={marks[0].value}
        defaultValue={marks[marks.length - 1].value}
        onChange={handleChange}
        color='secondary'
        marks={marks}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="auto"
        sx={slider}
      />
    </Box>
  </>;
};

export default SSTStepSlider;