import React, {FC} from 'react';

//MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import LockIcon from '@mui/icons-material/Lock';

//UTILS
import {useTranslation} from 'react-i18next';
import LoginWrapper from './LoginWrapper';

const SignUpConfirm: FC = () => {

  const {t} = useTranslation();

  return <LoginWrapper>
    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
      <LockIcon/>
    </Avatar>
    <Typography component='h1' variant='h5'>{t('login.signUpConfirm')}</Typography>
    <Box sx={{mt: 1}}>
      <Typography component='body' variant='body1'>{t('login.signUpConfirmText')}</Typography>
    </Box>
  </LoginWrapper>;
};

export default SignUpConfirm;