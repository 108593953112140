import Api from '../domain/services/Api';
import Campaign from '../domain/entities/Campaign';

const API_URL = process.env.API_URL;
const APPLICATION_JSON = 'application/json';

const getCampaigns = async (): Promise<Campaign[]> => {
  const response = await fetch(`${API_URL}/campaigns/`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    })
  });

  if (response.status === 200 && response.ok) {
    return response.json();
  } else {
    return Promise.reject();
  }
};

const getProfile = async (lat: number, lon: number): Promise<[]> => {
  const response = await fetch(`${API_URL}/profile?lat=${lat}&lon=${lon}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    })
  });

  if (response.status === 200 && response.ok) {
    return response.json();
  } else {
    return Promise.reject();
  }
};

const getFlow = async (): Promise<[]> => {
  const response = await fetch(`${API_URL}/flow/`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    })
  });

  if (response.status === 200 && response.ok) {
    return response.json();
  } else {
    return Promise.reject();
  }
};

const formatDate = (date: Date) => {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
};

const getWaves = async (initial_date: Date, final_date: Date, variable: string): Promise<[]> => {
  const response = await fetch(`${API_URL}/waves?initial_date=${formatDate(initial_date)}&final_date=${formatDate(final_date)}&variable=${variable}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    })
  });

  if (response.status === 200 && response.ok) {
    return response.json();
  } else {
    return Promise.reject();
  }
};

const ApiImpl: Api = {
  getCampaigns,
  getProfile,
  getFlow,
  getWaves
};

export default ApiImpl;