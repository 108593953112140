import StepSlider, {StepSliderProps} from '../StepSlider';
import {FC} from 'react';
import {PNOA_YEARS} from './config';


const configPNOAStepSlider = {
  dates: PNOA_YEARS
};

export const PNOAStepSlider: FC<StepSliderProps> = ({selectedDate, onDateChange}) =>
  StepSlider({
    configStepSlider: configPNOAStepSlider,
    selectedDate,
    onDateChange
  });


export default PNOAStepSlider;