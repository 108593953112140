export const PNOA_HISTORICO_ID = 'pnoa-historico';
export const PNOA_DEFAULT_YEAR = 2014;

export const PNOA_YEARS = [2014, 2017, 2020];

const PNOA_HISTORICO_SOURCES = PNOA_YEARS.reduce((acc, year) => ({
  ...acc,
  [year]: {
    'type': 'raster',
    'tiles': [
      `https://www.ign.es/wms/pnoa-historico?service=WMS&request=GetMap&version=1.1.1&layers=PNOA${year}&Style=default&Format=image/jpeg&srs=EPSG:3857&bbox={bbox-epsg-3857}&width=256&height=256`
    ],
    'tileSize': 256
  }
}), {});

export const PNOA_HISTORICO_BASEMAP = (year) => ({
  'version': 8,
  'name': 'PNOA',
  'glyphs': 'https://tileserver.geomatico.es/fonts/{fontstack}/{range}.pbf',
  'sources': PNOA_HISTORICO_SOURCES,
  'layers': [
    {
      'id': 'background',
      'type': 'background',
      'paint': {
        'background-color': '#F4F9F4'
      }
    },
    {
      'id': 'pnoa',
      'type': 'raster',
      'source': `${year}`,
      'maxzoom': 22
    }
  ]
});