//REACT
import React, {FC} from 'react';
import Draggable from 'react-draggable';

//MUI
import {LineChart} from '@mui/x-charts/LineChart';
import Dialog from '@mui/material/Dialog';
import Paper, {PaperProps} from '@mui/material/Paper';
import {DialogTitle, IconButton} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';


export type ChartFlowProps = {
  flow: [];
  open: boolean;
  handleButtonClose: () => void;
}

const PaperComponent = (props: PaperProps) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const prepareData = (flow: [], valueType: string) => {
  return flow.map((m) => {
    return {
      x: new Date(m['date']),
      y: m[valueType],
    };
  });
};

const ChartFlow: FC<ChartFlowProps> = ({flow, open, handleButtonClose}) => {

  const theme = useTheme();

  let meanData = [];
  let xMeanData: Date[] = [];
  let yMeanData: number[] = [];
  let maxData = [];
  let yMaxData: number[] = [];
  let minData = [];
  let yMinData: number[] = [];

  if (flow) {
    meanData = prepareData(flow, 'mean_flow');
    xMeanData = meanData.map(d => d.x);
    yMeanData = meanData.map(d => d.y);
    minData = prepareData(flow, 'min_flow');
    yMinData = minData.map(d => d.y);
    maxData = prepareData(flow, 'max_flow');
    yMaxData = maxData.map(d => d.y);
  }

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={false}
      hideBackdrop
    >
      <DialogTitle id="draggable-dialog-title" style={{cursor: 'move', backgroundColor: theme.palette.primary.main, color: 'white'}}>
        Evolución temporal del Caudal
        <IconButton style={{position: 'absolute', right: '8px', top: '8px'}} onClick={handleButtonClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div style={{backgroundColor: 'white'}}>
        <LineChart
          xAxis={[{
            data: xMeanData,
            scaleType: 'time',
            valueFormatter: (date) =>
              date.toLocaleDateString('es-ES', {
                // you can use undefined as first argument
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              })
          }]}
          series={[
            { id: 'mean',  data: yMeanData, color: 'green', label: 'Caudal Medio (m3/s)' },
            { id: 'max',  data: yMaxData, color: 'red', label: 'Caudal Máximo (m3/s)'},
            { id: 'min',  data: yMinData, color: 'blue', label: 'Caudal Mínimo (m3/s)'}
          ]}
          width={1000}
          height={400}
          grid={{ vertical: true, horizontal: true }}
        />
      </div>
    </Dialog>
  );
};

export default ChartFlow;