import Auth from '../domain/services/Auth';
import {ApiToken, LoginError, UserCreated, UserCreateParams, UserCreateParamsErrors} from '../domain/services/types';

const API_URL = process.env.API_URL;
const APPLICATION_JSON = 'application/json';

const login = async (email: string, password: string) => {

  const response = await fetch(`${API_URL}/auth/jwt/create/`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    }),
    body: JSON.stringify({email, password})
  });
  
  if (response.status === 200 && response.ok) {
    return response.json()
      .then((body: ApiToken) => body);
  } else if (response.status === 401) {
    return response.json()
      .then((body: LoginError) => body);
  } else {
    return Promise.reject();
  }
};

const verifyToken = async (token: string) => {

  const response = await fetch(`${API_URL}/auth/jwt/verify/`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    }),
    body: JSON.stringify({token})
  });

  if (response.status === 200 && response.ok) {
    return Promise.resolve();
  } else if (response.status === 401) {
    return Promise.reject();
  } else {
    return Promise.reject();
  }
};

const refreshToken = async (refresh: string) => {
  const response = await fetch(`${API_URL}/auth/jwt/refresh/`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    }),
    body: JSON.stringify({refresh})
  });

  if (response.status === 200 && response.ok) {
    return response.json()
      .then((body) => body);
  } else if (response.status === 401) {
    return response.json()
      .then((body) => body);
  } else {
    return Promise.reject();
  }
};

const activateUser = async (uid: string, token: string) => {
  const response = await fetch(`${API_URL}/auth/users/activation/`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    }),
    body: JSON.stringify({uid, token})
  });

  if (response.status === 204 && response.ok) {
    return Promise.resolve();
  } else if (response.status === 403) {
    return Promise.reject();
  } else {
    return Promise.reject();
  }
};

const singup = async (userParams: UserCreateParams) => {
  const response = await fetch(`${API_URL}/auth/users/`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    }),
    body: JSON.stringify({...userParams})
  });

  if (response.status === 201 && response.ok) {
    return response.json()
      .then((body: UserCreated) => body);
  } else if (response.status === 400) {
    return response.json()
      .then((body: UserCreateParamsErrors) => body);
  } else {
    return Promise.reject();
  }
};

const AuthImpl: Auth = {
  login,
  verifyToken,
  refreshToken,
  activateUser,
  singup
};

export default AuthImpl;