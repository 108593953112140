//REACT
import React, {FC} from 'react';
import Draggable from 'react-draggable';

//DOMAIN
import {Profile} from '../domain/entities/Measure';

//MUI
import {LineChart,
  lineElementClasses,
  markElementClasses
} from '@mui/x-charts/LineChart';
import Dialog from '@mui/material/Dialog';
import Paper, {PaperProps} from '@mui/material/Paper';
import {DialogTitle, IconButton} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

export type ChartProfileProps = {
  profile: Profile | null;
  open: boolean;
  handleButtonClose: () => void;
}

const PaperComponent = (props: PaperProps) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const prepareProfileData = (profile: Profile) => {
  return profile.measures.map((m) => {
    return {
      x: new Date(m.date),
      y: m.profile,
    };
  });
};
const prepareMeanData = (profile: Profile) => {
  return profile.measures.map((m) => {
    return {
      x: new Date(m.date),
      y: m.mean,
    };
  });
};
const prepareSelectionData = (profile: Profile) => {
  return profile.measures.map((m) => {
    return {
      x: new Date(m.date),
      y: m.selection,
    };
  });
};

const ChartProfile: FC<ChartProfileProps> = ({profile, open, handleButtonClose}) => {

  const theme = useTheme();

  let profileData = [];
  let meanData = [];
  let selectionData = [];
  let xAxisData: Date[] = [];
  let profileSeriesData: number[] = [];
  let meanSeriesData: number[] = [];
  let selectionSeriesData: number[] = [];

  if (profile) {
    profileData = prepareProfileData(profile);
    meanData = prepareMeanData(profile);
    selectionData = prepareSelectionData(profile);
    xAxisData = profileData.map(d => d.x);
    profileSeriesData = profileData.map(d => d.y * 100);
    meanSeriesData = meanData.map(d => d.y);
    selectionSeriesData = selectionData.map(d => d.y * 100);
  }

  const lineChartsParams = {
    series: [
      { data: profileSeriesData, color: 'blue', label: 'Topografía', id: 'profileId'},
      { data: meanSeriesData, color: 'grey', label: 'Media', id: 'meanId'},
      { data: selectionSeriesData, color: 'green', label: 'Selección', id: 'selectionId'}
    ]
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={false}
      hideBackdrop
    >
      <DialogTitle id="draggable-dialog-title" style={{cursor: 'move', backgroundColor: theme.palette.primary.main, color: 'white'}}>
        Topografía, Selección y Media
        <IconButton style={{position: 'absolute', right: '8px', top: '8px'}} onClick={handleButtonClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div style={{backgroundColor: 'white'}}>
        <LineChart
          {...lineChartsParams}
          xAxis={[{
            data: xAxisData,
            scaleType: 'time',
            valueFormatter: (date) =>
              date.toLocaleDateString('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              })
          }]}
          leftAxis={null}
          width={1000}
          height={400}
          series={lineChartsParams.series.map(
            (series) => ({
              ...series,
              valueFormatter: (v) => {
                if (v && series.id === 'meanId') {
                  return v.toString() ;
                }
                return v ? (v / 100).toString() : '';
              },
            }))}
          sx={{
            [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
              strokeWidth: 1,
            },
            '.MuiLineElement-series-profileId': {
              strokeDasharray: '3 4 5 2',
            },
            '.MuiLineElement-series-meanId': {
              strokeDasharray: '3 4 5 2',
            },
            '.MuiLineElement-series-selectionId': {
              strokeDasharray: '3 4 5 2',
            },
            [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]: {
              fill: '#fff',
            },
            [`& .${markElementClasses.highlighted}`]: {
              stroke: 'none',
            },
          }}
        />
      </div>
    </Dialog>
  );
};

export default ChartProfile;