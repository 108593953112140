import React, {FC, useState} from 'react';

//MUI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

//GEOCOMPONENTS
import BaseMapList from '@geomatico/geocomponents/Map/BaseMapList';
import LayerSwitcher from '@geomatico/geocomponents/Map/LayerSwitcher';

import SectionTitle from '../../components/SectionTitle';

//UTILS
import styled from '@mui/styles/styled';
import {INITIAL_MAPSTYLE, MAP_DETAIL_STYLES, MAPSTYLES} from '../../config';

//PNOA
import PNOAStepSlider from '../../components/pnoa/PNOAStepSlider';
import {PNOA_HISTORICO_ID} from '../../components/pnoa/config';

//ORTOS
import {MAXAR_ID} from '../../components/ortos/config';
import SelectMaxar from '../../components/SelectMaxar';

//ENTITIES
import {createCampaignTitles} from '../../components/CampaignTitle';
import Campaign from '../../domain/entities/Campaign';
import {Button} from '@mui/material';
import SSTStepSlider from '../../components/sst/SSTStepSlider';

const ScrollableContent = styled(Box)({
  overflow: 'auto',
  padding: '8px',
});

const stackSx = {
  height: '100%',
  overflow: 'hidden'
};

const layerSwitcherStyle = {
  '&.LayerSwitcher-root': {
    justifyContent: 'flex-start',
  }
};

export type SidePanelContentProps = {
  mapStyleId: string,
  mapDetail: Array<string>,
  campaigns: Campaign[],
  PNOASelectedYear: number,
  ORTOSSelectedDate: string,
  sstDisabled: boolean,
  flowDisabled: boolean,
  wavesDisabled: boolean,
  onFlowChange: (value: boolean) => void,
  onWavesClick: (value: boolean) => void,
  onStyleChange: (mapStyleId: string) => void,
  onPNOAYearChange: (value: number) => void,
  onMaxarDateChange: (value: string) => void,
  onMapDetailChange: (detail: Array<string>) => void,
  onCampaignChange: (campaign: Campaign, measure: string) => void,
  onSSTDateChange: (value: number) => void,
  onSSTDisabledChange: (value: boolean) => void
};

const SidePanelContent: FC<SidePanelContentProps> = ({
  mapStyleId,
  mapDetail,
  campaigns,
  onStyleChange,
  onPNOAYearChange,
  PNOASelectedYear,
  sstDisabled,
  flowDisabled,
  wavesDisabled,
  onFlowChange,
  onWavesClick,
  onMaxarDateChange,
  ORTOSSelectedDate,
  onMapDetailChange,
  onCampaignChange,
  onSSTDateChange,
  onSSTDisabledChange
}) => {

  const handleSSTDisabled = () => {
    onSSTDisabledChange(!sstDisabled);
  };

  const handleFlowChange = () => {
    onFlowChange(!flowDisabled);
  };

  const handleWavesClick = () => {
    onWavesClick(!wavesDisabled);
  };

  return <Stack sx={stackSx}>
    <ScrollableContent>
      <SectionTitle titleKey='baseMapStyle'/>
      <BaseMapList
        styles={MAPSTYLES}
        selectedStyleId={mapStyleId}
        onStyleChange={onStyleChange}
      />
      {
        mapStyleId === PNOA_HISTORICO_ID ? <PNOAStepSlider selectedDate={PNOASelectedYear} onDateChange={onPNOAYearChange}/> : undefined
      }
      {
        mapStyleId === MAXAR_ID ? <SelectMaxar selectedDate={ORTOSSelectedDate} onDateChange={onMaxarDateChange}/> : undefined
      }
      <SectionTitle titleKey='campaigns'/>
      {
        createCampaignTitles(campaigns, onCampaignChange)
      }
      <SectionTitle titleKey='sst'/>
      <Button variant="contained" color="primary" onClick={handleSSTDisabled}>SST</Button>
      <SSTStepSlider onDateChange={onSSTDateChange} disabled={sstDisabled}/>
      <SectionTitle titleKey='flow'/>
      <Button variant="contained" color="primary" onClick={handleFlowChange}>Caudal</Button>
      <SectionTitle titleKey='waves'/>
      <Button variant="contained" color="primary" onClick={handleWavesClick}>Oleaje</Button>
      <SectionTitle titleKey='conditions'/>
      <LayerSwitcher
        layers={MAP_DETAIL_STYLES}
        selectedLayerIds={mapDetail}
        exclusive={false}
        allowEmptyValue={true}
        onLayerChange={onMapDetailChange}
        itemWidth={72}
        sx={layerSwitcherStyle}
      />
    </ScrollableContent>
  </Stack>;
};

export default SidePanelContent;
