import {APP_NAME} from '../config';
import LocalStorage from '../domain/services/LocalStorage';


const getCredentials = () => {
  const accessToken = localStorage.getItem(`${APP_NAME}.accessToken`);
  const refreshToken = localStorage.getItem(`${APP_NAME}.refreshToken`);

  return {
    accessToken: accessToken || undefined,
    refreshToken: refreshToken || undefined
  };
};

const setCredentials = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(`${APP_NAME}.accessToken`, accessToken);
  localStorage.setItem(`${APP_NAME}.refreshToken`, refreshToken);
};

const removeCredentials = () => {
  localStorage.removeItem(`${APP_NAME}.accessToken`);
  localStorage.removeItem(`${APP_NAME}.refreshToken`);
};

const LocalStorageImpl: LocalStorage = {
  getCredentials,
  setCredentials,
  removeCredentials
};

export default LocalStorageImpl;