import React, {FC, useMemo, useState} from 'react';

//MUI
import Box from '@mui/material/Box';

//GEOCOMPONENTS
import Map from '@geomatico/geocomponents/Map/Map';

//
import layerConstructor from '../../components/utils/layers';

//UTILS
import {INITIAL_VIEWPORT, MAP_DETAILS} from '../../config';
import {AnyLayer, Style} from 'mapbox-gl';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import sourcesConstructor from '../../components/utils/sources';
import Campaign from '../../domain/entities/Campaign';

const imgLegendSx: SxProps = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  m: 1,
  p: 1
};

export type MainContentProps = {
  mapStyle: Style | string,
  mapDetail: Array<string>,
  campaign: Campaign | null,
  measure: string,
  sstDate: number | null,
  sstDisabled: boolean,
  flowDisabled: boolean,
  flow: [],
  handleMapClick: (e: any) => void
};

const MainContent: FC<MainContentProps> = ({mapStyle, mapDetail, campaign, measure, sstDate, sstDisabled, flowDisabled, handleMapClick}) => {
  const [viewport, setViewport] = useState(INITIAL_VIEWPORT);

  const detailLayers: Array<AnyLayer> = useMemo(() => {
    const layersResult = [];

    if (mapDetail.includes(MAP_DETAILS.REDNATURA)) {
      layersResult.push(...layerConstructor.redNatura());
    }

    if (campaign && measure == 'profile') {
      layersResult.push(...layerConstructor.colorized(), ...layerConstructor.dem());
    }

    if (campaign && measure == 'mean') {
      layersResult.push(...layerConstructor.mean());
    }

    if (campaign && measure == 'selection') {
      layersResult.push(...layerConstructor.selection());
    }

    if (sstDate && !sstDisabled) {
      layersResult.push(...layerConstructor.sst());
    }

    if (flowDisabled) {
      layersResult.push(...layerConstructor.flow());
    }

    layersResult.push(...layerConstructor.stations());

    return layersResult;
  }, [mapDetail, campaign, measure, sstDate, sstDisabled, flowDisabled]);

  const sources = useMemo(() => {
    return sourcesConstructor(campaign, measure, sstDate, flowDisabled);
  }, [campaign, measure, sstDate, flowDisabled]);

  const terrain = useMemo(() => {
    return campaign && measure == 'profile' ? {
      source: MAP_DETAILS.DEM,
      exaggeration: 20,
    } : undefined;
  }, [campaign]);

  const layers = useMemo(() => ([
    ...detailLayers,
  ]), [detailLayers]);

  return <Map
    mapStyle={mapStyle}
    viewport={viewport}
    sources={sources}
    terrain={terrain}
    layers={layers}
    onViewportChange={setViewport}
    onClick={handleMapClick}
  >
    {
      mapDetail.includes(MAP_DETAILS.REDNATURA) &&
      <Box
        component='img'
        src='https://wms.mapama.gob.es/sig/Biodiversidad/RedNatura/Leyenda/RedNatura.png'
        sx={imgLegendSx}/>
    }
    {
      campaign && measure == 'mean' &&
      <Box
        component='img'
        src='/Media.png'
        sx={imgLegendSx}/>
    }
    {
      campaign && measure == 'selection' &&
      <Box
        component='img'
        src='/seleccion.png'
        sx={imgLegendSx}/>
    }
    {
      campaign && measure == 'profile' &&
      <Box
        component='img'
        src='/topografia.png'
        sx={imgLegendSx}/>
    }
    {
      sstDate && !sstDisabled &&
      <Box
        component='img'
        src='/sst.png'
        sx={imgLegendSx}/>
    }
  </Map>;
};

export default MainContent;
